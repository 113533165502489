<template>
  <div class="col-12 mb-4">
  <div class="row">
    <div class="col-12 bold font17 normal-text-dark mb-2">
      {{ stepNumber }}. Plaque Message:
    </div>
    <div class="col-12">
      <div class="row align-items-center">
        <div class="col-md-10">
          <TextArea placeholder='Enter your personalized plaque message here e.g. "Sponsored by [Donor Name] for the Esaal-e-Sawaab of [Name}"' v-model="plaqueMessageValue" id="plaqueDescription" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    TextArea: defineAsyncComponent(() => import('@/components/TextArea.vue'))
  },
  props: ['plaqueMessage', 'stepNumber'],
  emits: ['update:plaqueMessage'],
  name: 'PlaqueMessage',
  computed: {
    plaqueMessageValue: {
      get () {
        return this.plaqueMessage
      },
      set (val) {
        this.$emit('update:plaqueMessage', val)
      }
    }
  }
}
</script>
